import React, { useEffect, useRef, useState } from 'react'
import Topo from './Topo'
import Rodape from './Rodape'

import './ExtremeAcamp2024.css'
import { Button, Checkbox, Col, ConfigProvider, DatePicker, Divider, Form, Input, Radio, Row, Select, Steps, message } from 'antd'
import theme from '../../services/theme'
import MaskedInput from '../../components/MaskedInput'
import locale from 'antd/es/date-picker/locale/pt_BR'
import axios from 'axios'
import api from '../../services/api'
import { useNavigate } from 'react-router-dom'

const InscreverSe = () => {
  useEffect(() => {
    document.title = 'Extreme Acamp 2024 - Inscrição'
  }, [])

  const [current, setCurrent] = useState(0)
  const [usuario, setUsuario] = useState({
    desejaCamisa: 0,
  })
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const steps = [
    { title: 'Identificação' }, { title: 'Contato' }, { title: 'Outros Dados' }, { title: 'Acesso' }, { title: 'Termos' }
  ]

  const handleSetCep = async (cep) => {
    setUsuario({ ...usuario, cep: cep })

    if (cep.length < 8) return

    const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)

    if (data.logradouro) {
      setUsuario({ ...usuario, logradouro: data.logradouro, bairro: data.bairro, cidade: data.localidade, estado: data.uf, cep: cep })
    }
  }

  const enviaDados = async () => {
    if (!usuario?.nome) { message.error('O campo nome é obrigatório'); return; }
    if (!usuario?.cpf) { message.error('O campo CPF é obrigatório'); return; }
    if (!usuario?.dataNascimento) { message.error('O campo Data de Nascimento é obrigatório'); return; }
    if (!usuario?.congregacao) { message.error('O campo Congregação é obrigatório'); return; }
    if (!usuario?.formaPagamento) { message.error('O campo Forma de Pagamento é obrigatório'); return; }
    if (!usuario?.quantidadeParcelas) { message.error('O campo Opção de Parcelamento é obrigatório'); return; }
    if (!usuario?.sexo) { message.error('O campo Sexo é obrigatório'); return; }


    if (!usuario?.whatsapp) { message.error('O campo Whatsapp é obrigatório'); return; }

    if (!usuario?.desejaCamisa == null) { message.error('O campo Deseja camisa? é obrigatório'); return; }

    if (!usuario?.email) { message.error('O campo E-mail é obrigatório'); return; }
    if (!usuario?.senha) { message.error('O campo Senha é obrigatório'); return; }
    if (!usuario?.repetirSenha) { message.error('O campo Repetir Senha é obrigatório'); return; }
    if (usuario?.senha !== usuario?.repetirSenha) { message.error('As senhas não conferem'); return; }
    if (usuario?.senha.length < 6) { message.error('A senha deve ter no mínimo 6 caracteres'); return; }

    if (!usuario?.acordoARegras) { message.error('Para prosseguir é necessário concordar com os termos.'); return; }
    if (!usuario?.cumprirAtividades) { message.error('Você deve concordar em cumprir todas as atividades do evento'); return; }

    try {
      setLoading(true)

      const response = await api.post('/auth/register', {
        ...usuario,
        area: '26'
      })

      if (response.data.id) {
        message.success('Inscrição realizada com sucesso! Você será redirecionado!')

        const response = await api.post('/auth/login', {
          email: usuario.email,
          senha: usuario.senha
        })

        if (response.data.token) {
          localStorage.setItem('area26token', response.data.token)
          localStorage.setItem('area26user', JSON.stringify(response.data.user))

          navigate('/extreme-acamp-2024/home')
        } else {
          throw new Error('Erro ao realizar o login. Tente novamente mais tarde.')
        }

      } else {
        throw new Error('Erro ao enviar os dados. Tente novamente mais tarde.')
      }

    } catch (e) {
      if (e?.response?.data?.errors) {
        e.response.data.errors.forEach(error => {
          message.error(error.msg)
        })
      } else if (e?.response?.data?.message) {
        message.error(e.response.data.message)
      } else {
        message.error('Erro ao enviar os dados. Tente novamente mais tarde.')
      }

    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <ConfigProvider theme={theme} locale={locale}>
        <Topo />

        <div id="content-container">
          <div id="content">

            <h1>Inscrever-se</h1>
            <p>Inscreva-se para participar do Extreme Acamp 2024!</p>

            <Divider style={{ color: '#3CF517', borderBlockStartColor: '#3CF517' }} />

            <Form
              layout='vertical'
              onFinish={enviaDados}
            >

              <Steps
                current={current}
                items={steps}
                labelPlacement='vertical'
              />

              <Row gutter={12} style={{ marginTop: '80px' }}>
                {(current == 0) && (
                  <>
                    <Col md={10} xs={24}>
                      <Form.Item label='Nome Completo' required>
                        <Input value={usuario?.nome} onChange={e => setUsuario({ ...usuario, nome: e.target.value })} />
                      </Form.Item>
                    </Col>

                    <Col md={7} xs={24}>
                      <Form.Item label='CPF' required>
                        <MaskedInput mask='000.000.000-00' value={usuario?.cpf} onChange={e => setUsuario({ ...usuario, cpf: e.target.value.trim() })} />
                      </Form.Item>
                    </Col>

                    <Col md={7} xs={24}>
                      <Form.Item label='Data de Nascimento' required>
                        <MaskedInput mask='00/00/0000' value={usuario?.dataNascimento} onChange={e => setUsuario({ ...usuario, dataNascimento: e.target.value })} />
                      </Form.Item>
                    </Col>

                    <Col md={6} xs={24}>
                      <Form.Item label='Congregação' required>
                        <Select
                          options={[
                            { value: 1, label: <span>Congregação 01</span> },
                            { value: 12, label: <span>Congregação 12</span> },
                            { value: 14, label: <span>Congregação 14</span> },
                            { value: 19, label: <span>Congregação 19</span> },
                            { value: 22, label: <span>Congregação 22</span> },
                          ]}
                          onChange={value => setUsuario({ ...usuario, congregacao: value })}
                          value={usuario?.congregacao}
                        />
                      </Form.Item>
                    </Col>

                    <Col md={6} xs={24}>
                      <Form.Item label='Forma de Pagamento' required>
                        <Select
                          options={[
                            { value: 'PIX', label: <span>PIX</span> },
                            { value: 'DIN', label: <span>Dinheiro</span> },
                            { value: 'CAR', label: <span>Cartão de Crédito</span> }
                          ]}
                          onChange={(value) => {
                            setUsuario({ ...usuario, formaPagamento: value })
                          }}
                          value={usuario?.formaPagamento}
                        />
                      </Form.Item>
                    </Col>

                    <Col md={6} xs={24}>
                      <Form.Item label='Opção de Parcelamento' required>
                        <Select
                          options={[
                            { value: 1, label: <span>À Vista</span> },
                            { value: 2, label: <span>02 Parcelas</span> },
                            { value: 3, label: <span>03 Parcelas</span> },
                            { value: 4, label: <span>04 Parcelas</span> }
                          ]}
                          onChange={value => setUsuario({ ...usuario, quantidadeParcelas: value })}
                          value={usuario?.quantidadeParcelas}
                        />
                      </Form.Item>
                    </Col>

                    <Col md={6} xs={24}>
                      <Form.Item label='Sexo' required>
                        <Radio.Group
                          onChange={e => setUsuario({ ...usuario, sexo: e.target.value })}
                          value={usuario?.sexo}
                        >
                          <Radio value='M' style={{ color: 'white' }}>Masculino</Radio>
                          <Radio value='F' style={{ color: 'white' }}>Feminino</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </>
                )}

                {(current == 1) && (<>
                  <Col md={6} xs={24}>
                    <Form.Item label='Whatsapp' required>
                      <MaskedInput mask='(00) 00000-0000' value={usuario?.whatsapp} onChange={e => setUsuario({ ...usuario, whatsapp: e.target.value.trim() })} />
                    </Form.Item>
                  </Col>

                  <Col md={6} xs={24}>
                    <Form.Item label='Contato de Emergência'>
                      <MaskedInput mask='(00) 00000-0000' value={usuario?.telefoneEmergencia} onChange={e => setUsuario({ ...usuario, telefoneEmergencia: e.target.value })} />
                    </Form.Item>
                  </Col>

                  <Col md={12} xs={24}>
                    <Form.Item label='Nome do Contato de Emergência'>
                      <Input value={usuario?.contatoEmergencia} onChange={e => setUsuario({ ...usuario, contatoEmergencia: e.target.value })} />
                    </Form.Item>
                  </Col>

                  <Col md={4} xs={24}>
                    <Form.Item label='CEP'>
                      <MaskedInput mask='00000000' value={usuario?.cep} onChange={e => handleSetCep(e.target.value)} />
                    </Form.Item>
                  </Col>

                  <Col md={8} xs={24}>
                    <Form.Item label='Logradouro'>
                      <Input value={usuario?.logradouro} onChange={e => setUsuario({ ...usuario, logradouro: e.target.value })} />
                    </Form.Item>
                  </Col>

                  <Col md={4} xs={24}>
                    <Form.Item label='Número'>
                      <Input value={usuario?.numero} onChange={e => setUsuario({ ...usuario, numero: e.target.value })} />
                    </Form.Item>
                  </Col>

                  <Col md={8} xs={24}>
                    <Form.Item label='Bairro'>
                      <Input value={usuario?.bairro} onChange={e => setUsuario({ ...usuario, bairro: e.target.value })} />
                    </Form.Item>
                  </Col>

                </>)}

                {(current == 2) && (
                  <>
                    <Col md={6} xs={24}>
                      <Form.Item label='Deseja camisa?' required>
                        <Radio.Group
                          onChange={e => setUsuario({ ...usuario, desejaCamisa: e.target.value })}
                          value={usuario?.desejaCamisa}
                        >
                          {/* <Radio value={1} style={{ color: 'white' }}>Sim</Radio> */}
                          <Radio value={0} style={{ color: 'white' }}>Não</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>

                    <Col md={6} xs={24}>
                      <Form.Item label='Tamanho da camisa' required={usuario?.desejaCamisa == 1}>
                        <Select
                          options={[
                            { value: 'PP', label: <span>PP</span> },
                            { value: 'P', label: <span>P</span> },
                            { value: 'M', label: <span>M</span> },
                            { value: 'G', label: <span>G</span> },
                            { value: 'GG', label: <span>GG</span> },
                            { value: 'G1', label: <span>G1</span> },
                            { value: 'G3', label: <span>G3</span> },
                            { value: 'G5', label: <span>G5</span> },
                          ]}
                          onChange={value => setUsuario({ ...usuario, tamanhoCamisa: value })}
                          value={usuario?.tamanhoCamisa}
                          disabled={usuario?.desejaCamisa != 1}
                        />
                      </Form.Item>
                    </Col>

                    <Col md={6} xs={24}>
                      <Form.Item label='Tem algum problema de saúde?'>
                        <Radio.Group
                          onChange={e => setUsuario({ ...usuario, problemaDeSaude: e.target.value })}
                          value={usuario?.problemaDeSaude}
                        >
                          <Radio value={1} style={{ color: 'white' }}>Sim</Radio>
                          <Radio value={0} style={{ color: 'white' }}>Não</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>

                    <Col md={6} xs={24}>
                      <Form.Item label='Qual Problema de Saúde?'>
                        <Input value={usuario?.descricaoProblemaDeSaude} onChange={e => setUsuario({ ...usuario, descricaoProblemaDeSaude: e.target.value })} disabled={usuario?.problemaDeSaude != 1} />
                      </Form.Item>
                    </Col>

                    <Col md={6} xs={24}>
                      <Form.Item label='Toma Algum Medicamento?'>
                        <Radio.Group
                          onChange={e => setUsuario({ ...usuario, medicamento: e.target.value })}
                          value={usuario?.medicamento}
                        >
                          <Radio value={1} style={{ color: 'white' }}>Sim</Radio>
                          <Radio value={0} style={{ color: 'white' }}>Não</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>

                    <Col md={6} xs={24}>
                      <Form.Item label='Qual Medicamento?'>
                        <Input value={usuario?.descricaoMedicamento} onChange={e => setUsuario({ ...usuario, descricaoMedicamento: e.target.value })} disabled={usuario?.medicamento != 1} />
                      </Form.Item>
                    </Col>

                    <Col md={6} xs={24}>
                      <Form.Item label='Possui Alergia?'>
                        <Radio.Group
                          onChange={e => setUsuario({ ...usuario, alergia: e.target.value })}
                          value={usuario?.alergia}
                        >
                          <Radio value={1} style={{ color: 'white' }}>Sim</Radio>
                          <Radio value={0} style={{ color: 'white' }}>Não</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>

                    <Col md={6} xs={24}>
                      <Form.Item label='Qual Alergia?'>
                        <Input value={usuario?.descricaoAlergia} onChange={e => setUsuario({ ...usuario, descricaoAlergia: e.target.value })} disabled={usuario?.alergia != 1} />
                      </Form.Item>
                    </Col>

                    <Col md={6} xs={24}>
                      <Form.Item label='Toma Algum Remédio Controlado?'>
                        <Radio.Group
                          onChange={e => setUsuario({ ...usuario, remedioControlado: e.target.value })}
                          value={usuario?.remedioControlado}
                        >
                          <Radio value={1} style={{ color: 'white' }}>Sim</Radio>
                          <Radio value={0} style={{ color: 'white' }}>Não</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>

                    <Col md={6} xs={24}>
                      <Form.Item label='Qual Remédio Controlado?'>
                        <Input value={usuario?.descricaoRemedioControlado} onChange={e => setUsuario({ ...usuario, descricaoRemedioControlado: e.target.value })} disabled={usuario?.remedioControlado != 1} />
                      </Form.Item>
                    </Col>
                  </>
                )}

                {(current == 3) && (
                  <>
                    <Col md={24} xs={24} style={{ marginBottom: '50px', color: 'white' }}>
                      <p><b>ATENÇÃO</b></p>
                      <p>Digite um e-mail que você lembrará depois. Ele será usado para acessar sua conta.</p>
                      <p>Escolha uma senha de 6 ou mais caracteres.</p>
                    </Col>

                    <Col md={8} xs={24}>
                      <Form.Item label='E-mail' required>
                        <Input value={usuario?.email} onChange={e => setUsuario({ ...usuario, email: e.target.value })} />
                      </Form.Item>
                    </Col>

                    <Col md={8} xs={24}>
                      <Form.Item label='Senha' required>
                        <Input value={usuario?.senha} onChange={e => setUsuario({ ...usuario, senha: e.target.value })} type='password' />
                      </Form.Item>
                    </Col>

                    <Col md={8} xs={24}>
                      <Form.Item label='Repetir Senha' required>
                        <Input
                          value={usuario?.repetirSenha}
                          onChange={e => setUsuario({ ...usuario, repetirSenha: e.target.value })}
                          type='password'
                          status={usuario?.senha === usuario?.repetirSenha ? 'success' : 'error'}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}

                {(current == 4) && (
                  <>
                    <Col md={24} xs={24} id='termos'>
                      <p style={{ textAlign: 'center' }}><b>REGRAS INSTITUCIONAIS DO EXTREME ACAMPA 2K24</b></p>
                      <br />
                      <p><b><i>INSCRIÇÕES ATÉ O DIA 14/09</i></b></p>
                      <br /><br />
                      <p>
                        <b>AVISOS IMPORTANTES ANTES DA COMPRA:</b><br /><br />

                        1- Ao efetuar o pagamento via pix, automaticamente será constado e devidamente inscrito no acampa.<br />
                        2- Qualquer erro na hora da compra, deverá ser salvo (printado), para que ao entrar em contato com nosso WhatsApp da A.D. MUTIRÃO (92) 9 8137-8961, pois somente desta forma, teremos como verificar o ocorrido e sanar qualquer erro.<br />
                        3- Se a compra for no pagamento facilitado mediante parcelamento, deve ser solicitado via site, sendo de responsabilidade do acampista em manter suas parcelas em dia, honrando a data limite de pagamento das parcelas, deve-se observar as regras disposta no item 2.<br />
                        <br />
                        <b>SOBRE INGRESSO DE CRIANÇAS</b><br />
                        <b>Não recomendamos que os acampistas levem crianças, exceto em caso de extrema necessidade. (criança de colo)</b>
                        <br /><br />

                        <b>1-	MEIA ENTRADA:</b><br />
                        Nosso Acampa, tem caráter estritamente religioso e confessional, por isso não poderá ser considerado um evento de lazer, entretenimento, educativo ou mesmo espetáculo musical. Devido a esta natureza, eles não se adequam aos conceitos presentes nas Leis Nacional, Estadual ou mesmo Municipal, para fins de obrigação de concessão da meia-entrada.
                        Depreende-se claramente da norma que rege o direito à meia-entrada que a aplicação da lei somente se dará quanto a salas de cinema, cineclubes, teatros, espetáculos musicais e circenses e eventos educativos, esportivos, de lazer e de entretenimento que sejam realizados, em rol taxativo já exposto, por quaisquer entidades, em espaços públicos ou privados. Assim, entendemos que o direito à meia-entrada, não se aplica a quaisquer eventos de cunho religioso.
                        <br /><br />

                        <b>2-	DO PAGAMENTO</b><br />
                        O Valor do passaporte do acampamento é de R$ 200,00 (Duzentos reais), o qual será utilizado para custear a estadia, alimentação, transporte e programações em geral do acampamento.<br />

                        Obs. A camisa não está inclusa no valor do ingresso, sendo opcional, a camisa, boné, chaveiro entre outros, devem ser encomendados na lojinha do admutirão no Instagram.<br />

                        Fica também disponível os tipos de pagamento:<br />
                        2.1 Do pagamento a vista:<br />
                        Pode ser realizado via pix em nossa plataforma;<br />
                        2.2 Do Pagamento no Cartão de credito:<br />
                        Fica a critério do acampista, pagar sua inscrição em cartão de credito, porém, fica estabelecido que o mesmo deve arcar, com os juros de parcelamento e transação da maquineta.<br />
                        2.3. Do Pagamento Parcelado:<br />
                        O acampista deve solicitar o pagamento facilitado em forma de parcelas, preenchendo o formulário de solicitação, observando as regras de pagamento:<br />
                        •	1º Parcela – vencimento no dia 22/06;<br />
                        •	2º Parcela – vencimento no dia 20/07;<br />
                        •	3º Parcela – vencimento no dia 24/08;<br />
                        •	4º Parcela – vencimento no dia 21/09.<br />
                        2.4 – O prazo para solicitar o pagamento facilitado é até o dia 22/06;<br />
                        2.5 – O atraso de 2 parcelas, resulta no cancelamento do plano, nesse caso o acampista tem até o dia 14/09 para realizar o pagamento total do seu acampa.<br />
                        <br /><br />

                        <b>3-	DA TRANSFERÊNCIA DE PASSAPORTE</b><br />
                        Entendemos que imprevistos acontecem, por isso, acampista que desejar transferir sua inscrição para outra pessoa, poderá fazê-lo da seguinte forma:<br />
                        Ele precisará preencher a carta modelo que disponibilizamos para você abaixo, após preenchida e obedecido todos os requisitos nela escritos no cabeçalho, o novo acampista, deverá encaminha-a mediante o WhatsApp da A.D. MUTIRÃO (92) 9 8137-8961. Fique atento aos requisitos necessários para a validade da carta, contidos no cabeçalho da mesma.<br />
                        <br />

                        <b>4-	REGRAS DE REEMBOLSO</b><br />
                        O Art. 49, da Lei 8.078/09, do Código de Defesa do Consumidor, lhe garante um prazo legal de 7 (sete) dias após a compra online, a contar do dia seguinte da aquisição, para solicitar o estorno integral do pagamento efetuado. Se você já passou deste prazo, antes de avançar, uma vez que todo recurso do acampa, são imediatamente investidos, fique ciente que o reembolso gerará descontos por custos de sistema e plataforma, utilizados pelo evento, assim como da multa pela desistência, que serão explicadas em porcentagens abaixo.<br />
                        <br />

                        <b>5-	PRAZO PARA REEMBOLSO:</b><br />
                        O reembolso só será possível até 30 dias antes do evento, obedecendo as devidas porcentagens de multas e taxas. Após este prazo, informamos que não haverá nenhuma devolução de valor.<br />
                        <br />

                        <b>6-	DA MULTA:</b><br />
                        Como já dito acima, o EXTREME ACAMP investe todo o valor de inscrição no evento, para que tenhamos êxito. Com isso, entendemos que, quanto mais se aproxima o evento, mais dificultoso se torna desfazer da compra feita, uma vez que tudo já está investido no Congresso. Com isso, os reembolsos serão geridos de acordo com a proximidade do evento, da seguinte forma:<br />
                        <br />
                        •	2 MESES ANTES: perda de 40% do valor do ingresso.<br />
                        •	1 MÊS ANTES: perda de 50% do valor do ingresso.<br />
                        <br /><br />

                        <b>7-	DA PARTICIPAÇÃO:</b><br />
                        Para proteger os direitos de nossos participantes, o valor recebido referente à inscrição, é imediatamente investido no evento, a fim de garantir a participação integral do acampista, nas programações apresentadas. A desinformação do acampista, de sua desistência, dentro do prazo estipulado acima pelo evento, configura-se, para a organização, a participação do mesmo.<br />
                        <br />

                        <b>8-	SOLICITAÇÃO DE REEMBOLSO:</b><br />
                        Caso queira continuar com o reembolso, você poderá solicita-lo pelo nosso atendimento via WhatsApp (92) 9 8137-8961, não se preocupe com a demora, será validado o dia do primeiro contato feito.<br />

                        <br />
                        Outras dúvidas, poderão ser tiradas através da nossa central de atendimentos via WhatsApp (92) 9 8137-8961<br /><br />

                        <b>9-	DAS REGRAS E DIRETRIZES</b><br />
                        O acampamento é submetido por regras e diretrizes, para melhor participação de todos, assim como também para evitar qualquer tipo de desentendimento e/ou situações constrangedoras. Assim, o acampista deve comprometer-se nesse período a se submeter as programações e atividades que serão desempenhadas ao decorrer do acampa e respeitar os direcionamentos, honrando seus pastores, líderes e equipe.<br />
                        Deve-se observar, as regras das equipes da gincana do acampamento que são divulgadas e entregues no momento em que o acampista recebe o “Kit Acampa”<br />

                        <br />

                        <b>10-	DO MENOR INSCRITO</b><br />
                        Em caso de acampista menor de idade, deve-se o responsável atentar as regras do acampa e autorizar a participação do mesmo no ato de inscrição.<br />
                        <br />

                        <b>11-	DO NÚMERO DE EMERGÊNCIA E MEDICAMENTOS</b><br />
                        O acampista deve informar um número de contato para casos de emergência, caso o acampista tenha alergia algum tipo de comida e/ou medicamento, deve ser informado no ato da inscrição, deve também o mesmo estar atento para as atividades que por motivo de saúde não possa participar.<br />
                        <br />

                        <b>12-	DO LOCAL E DATA</b><br />
                        O EXTREME ACAMPA 2K24 ocorrerá nos dias 27, 28 e 29 de setembro de 2024, na chácara Paraiso Verde, no Ramal do Calderão, km 13, Iranduba/AM.<br />
                        A saída do acampamento será no dia 27 de setembro de 2024 às 18:30h, é de responsabilidade do acampista estar presente no horário proposto, a volta será do dia 29 de setembro de 2024 as 17h.<br />

                        <br /><br />

                        <b>À Direção de Eventos<br />
                          AD MUTIRÃO – UMA IGREJA APAIXONANTE</b>

                      </p>
                    </Col>

                    <Col md={24} xs={24} style={{ marginTop: '50px' }}>
                      <Checkbox onChange={e => setUsuario({ ...usuario, acordoARegras: e.target.checked })} style={{ color: 'white' }} checked={usuario?.acordoARegras}>Li e concordo com os termos</Checkbox><br />
                      <Checkbox onChange={e => setUsuario({ ...usuario, cumprirAtividades: e.target.checked })} style={{ color: 'white' }} checked={usuario?.cumprirAtividades}>Estou de acordo com as regras e cumprirei todas as atividades do evento</Checkbox>
                    </Col>
                  </>
                )}


                <Col md={24} xs={24} style={{ marginTop: '50px' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                    {current > 0 && (
                      <Button onClick={() => setCurrent(current - 1)}>
                        Anterior
                      </Button>
                    )}
                    {current < steps.length - 1 && (
                      <Button type="primary" onClick={() => {
                        if (current == 0) {
                          if (!usuario?.nome) { message.error('O campo nome é obrigatório'); return; }
                          if (!usuario?.cpf) { message.error('O campo CPF é obrigatório'); return; }
                          if (!usuario?.dataNascimento) { message.error('O campo Data de Nascimento é obrigatório'); return; }
                          if (!usuario?.congregacao) { message.error('O campo Congregação é obrigatório'); return; }
                          if (!usuario?.formaPagamento) { message.error('O campo Forma de Pagamento é obrigatório'); return; }
                          if (!usuario?.quantidadeParcelas) { message.error('O campo Opção de Parcelamento é obrigatório'); return; }
                          if (!usuario?.sexo) { message.error('O campo Sexo é obrigatório'); return; }
                        }

                        if (current == 1) {
                          if (!usuario?.whatsapp) { message.error('O campo Whatsapp é obrigatório'); return; }
                        }

                        if (current == 2) {
                          if (!usuario?.desejaCamisa == null) { message.error('O campo Deseja camisa? é obrigatório'); return; }
                          if (usuario?.desejaCamisa == 1 && !usuario?.tamanhoCamisa) { message.error('O campo tamanho da camisa é obrigatório quando deseja camisa'); return; }
                        }

                        if (current == 3) {
                          if (!usuario?.email) { message.error('O campo E-mail é obrigatório'); return; }
                          if (!usuario?.senha) { message.error('O campo Senha é obrigatório'); return; }
                          if (!usuario?.repetirSenha) { message.error('O campo Repetir Senha é obrigatório'); return; }
                          if (usuario?.senha !== usuario?.repetirSenha) { message.error('As senhas não conferem'); return; }
                          if (usuario?.senha.length < 6) { message.error('A senha deve ter no mínimo 6 caracteres'); return; }
                        }

                        if (current == 4) {
                          if (!usuario?.acordoARegras) { message.error('Para prosseguir é necessário concordar com os termos.'); return; }
                          if (!usuario?.cumprirAtividades) { message.error('Você deve concordar em cumprir todas as atividades do evento'); return; }
                        }

                        setCurrent(current + 1)
                      }}>
                        Próximo
                      </Button>
                    )}
                    {current === steps.length - 1 && (
                      <Button type="primary" onClick={() => enviaDados()} loading={loading}>
                        Finalizar
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            </Form>

          </div>
        </div>

        <Rodape />
      </ConfigProvider>
    </>
  )
}

export default InscreverSe