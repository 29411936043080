import { Button, Col, Form, message, Modal, Row, Spin, Table, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import MaskedInput from '../../components/MaskedInput'
import api from '../../services/api'
import QRCode from "react-qr-code"

const TabelaPedidos = ({ pedidos, onPagamentoFinalizado }) => {
    const [meusPedidos, setMeusPedidos] = useState([])
    const [showModalPagamento, setShowModalPagamento] = React.useState(false)
    const [pedidoModalPagamento, setPedidoModalPagamento] = React.useState(null)
    const [valorRestante, setValorRestante] = React.useState(0)

    const [valorPagamento, setValorPagamento] = React.useState(0)
    const [etapaPagamento, setEtapaPagamento] = React.useState(1)
    const [loading, setLoading] = React.useState(false)

    const [qrCode, setQrCode] = React.useState('')
    const [codigoApiBanco, setCodigoApiBanco] = React.useState('')
    const [cobranca, setCobranca] = React.useState({})

    useEffect(() => {
        setMeusPedidos(pedidos)
    }, [pedidos])

    const verificaStatusDoPagamento = async () => {
        try {
            setLoading(true)
            const response = await api.get(`/pagamento/verificar-status/${cobranca.id}`)
            if (response.data?.status === 'CONCLUIDA') {
                message.success('Pagamento realizado com sucesso!')
                setShowModalPagamento(false)
                setPedidoModalPagamento(null)
                setEtapaPagamento(1)
                setValorPagamento(0)
                onPagamentoFinalizado()
            } else {
                message.error('O pagamento ainda não foi confirmado. Tente novamente mais tarde.')
            }
        } catch (e) {
            console.log(e)
            if (e.response?.data?.message) {
                message.error(e.response.data.message)
            } else {
                message.error('Ocorreu um erro ao verificar o status do pagamento. Tente novamente mais tarde.')
            }
        } finally {
            setLoading(false)
        }
    }

    const gerarQrCode = async () => {
        try {
            setLoading(true)
            const response = await api.post('/pagamento/gerar-qr-code', { valor: valorPagamento, pedidoId: pedidoModalPagamento.id })

            if (response.data?.qrCode) {
                setQrCode(response.data.qrCode)
                setCodigoApiBanco(response.data.codigoApiBanco)
                setCobranca(response.data.cobranca)
            } else {
                setQrCode('')
                setCodigoApiBanco('')
                setCobranca({})
                throw new Error('Erro ao gerar o QR Code. Tente novamente mais tarde.')
            }
        } catch (e) {
            console.log(e)
            if (e.response?.data?.message) {
                message.error(e.response.data.message)
            } else {
                message.error('Ocorreu um erro ao gerar o QR Code. Tente novamente mais tarde.')
            }
        } finally {
            setLoading(false)
        }
    }

    const coluns = [
        {
            title: 'Item',
            dataIndex: 'produto',
            key: 'produto',
            render: (produto) => {
                return <span style={{ whiteSpace: 'nowrap' }}>
                    {produto}
                </span>
            }
        },
        {
            title: 'Qtd.',
            dataIndex: 'quantidade',
            key: 'quantidade',
            render: (quantidade) => {
                return <span style={{ whiteSpace: 'nowrap' }}>
                    {quantidade}
                </span>
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status, record) => {
                if (status === 'PEN') {
                    if (record.valorPago > 0) return (<Tag color='blue' bordered={false}>PAGO PARCIALMENTE</Tag>)

                    return (<Tag color='blue' bordered={false}>PENDENTE</Tag>)
                }
                if (status === 'PAG') return (<Tag color='success' bordered={false}>PAGO</Tag>)
                if (status === 'REC') return (<Tag color='red' bordered={false}>RECUSADO</Tag>)
                if (status === 'CAN') return (<Tag color='default' bordered={false}>CANCELADO</Tag>)
                if (status === 'EMI') return (<Tag color='gold' bordered={false}>EMITIDO</Tag>)

                return <span style={{ whiteSpace: 'nowrap' }}>{status}</span>
            }
        },
        {
            title: 'Valor Total',
            dataIndex: 'valorTotal',
            key: 'valorTotal',
            render: (valorTotal) => {
                return <span style={{ whiteSpace: 'nowrap' }}>
                    R$ {valorTotal}
                </span>
            }
        },
        {
            title: 'Valor Pago',
            dataIndex: 'valorPago',
            key: 'valorPago',
            render: (valorPago) => {
                return <span style={{ whiteSpace: 'nowrap' }}>
                    R$ {valorPago}
                </span>
            }
        },
        {
            title: 'Valor Restante',
            dataIndex: 'valorRestante',
            key: 'valorRestante',
            render: (_, record) => {
                return <span style={{ whiteSpace: 'nowrap' }}>
                    R$ {(record.valorTotal - record.valorPago).toFixed(2)}
                </span>
            }
        },
        {
            title: 'Ações',
            dataIndex: 'acoes',
            key: 'acoes',
            render: (_, record) => {
                return <>
                    {record.produto.includes('Camisa') && record.status === 'PEN' && record.valorPago > 0 && (
                        <Button onClick={() => {
                            setPedidoModalPagamento(record)
                            setShowModalPagamento(true)
                            setValorRestante(record.valorTotal - record.valorPago)
                        }} type='primary' size='small'>
                            FAZER PAGAMENTO
                        </Button>
                    )}
                    {record.status === 'PEN' && !record.produto.includes('Camisa') && (
                        <Button onClick={() => {
                            setPedidoModalPagamento(record)
                            setShowModalPagamento(true)
                            setValorRestante(record.valorTotal - record.valorPago)
                        }} type='primary' size='small'>
                            FAZER PAGAMENTO
                        </Button>
                    )}
                </>
            }
        }
    ]

    return (
        <>
            <div style={{ backgroundColor: 'white', overflow: 'auto', padding: '10px' }}>
                <Table
                    columns={coluns}
                    dataSource={meusPedidos}
                    size="small"
                    locale={{
                        emptyText: 'Nenhum pedido encontrado'
                    }}
                />
            </div>

            <Modal
                title='REALIZAR PAGAMENTO'
                open={showModalPagamento}
                footer={null}
                onClose={() => { setShowModalPagamento(false); setPedidoModalPagamento(null); setEtapaPagamento(1); setValorPagamento(0); setLoading(false); setValorRestante(0) }}
                onCancel={() => { setShowModalPagamento(false); setPedidoModalPagamento(null); setEtapaPagamento(1); setValorPagamento(0); setLoading(false); setValorRestante(0) }}
            >
                {etapaPagamento === 1 && (
                    <>
                        <Row gutter={12} id='fazer-pagamento'>
                            <Form labelAlign='vertical' layout='vertical' style={{ width: '100%' }}>
                                <Col md={24} xs={24} style={{ marginTop: '20px' }}>
                                    <Form.Item label='Valor a Pagar' required>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ marginRight: '15px' }}>R$</div>
                                            <MaskedInput mask='000' value={valorPagamento} onChange={e => setValorPagamento(e.target.value)} status={valorPagamento > 0 && valorPagamento < 1 ? 'error' : 'success'} />
                                            <div style={{ marginLeft: '15px' }}>,00</div>
                                        </div>
                                        {valorPagamento > 0 && valorPagamento < 1 && (
                                            <span style={{ textAlign: 'center', display: 'block', color: 'red', marginTop: '5px' }}><i>Você deve pagar um valor maior ou igual a R$ 1,00</i></span>
                                        )}
                                        {valorPagamento > valorRestante && (
                                            <span style={{ textAlign: 'center', display: 'block', color: 'red', marginTop: '5px' }}><i>Você deve pagar um valor menor ou igual ao valor restante. ({new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valorRestante)})</i></span>
                                        )}
                                    </Form.Item>
                                </Col>

                                <Col md={24} xs={24} style={{ marginTop: '40px' }}>
                                    <Form.Item>
                                        <Button
                                            type='primary'
                                            block
                                            disabled={
                                                valorPagamento < 1 && valorPagamento <= valorRestante
                                            }
                                            onClick={() => {
                                                setEtapaPagamento(2)
                                                gerarQrCode()
                                            }}
                                        >REALIZAR PAGAMENTO</Button>
                                    </Form.Item>
                                </Col>
                            </Form>

                        </Row>
                    </>
                )}

                {etapaPagamento === 2 && (
                    <>
                        {loading && (
                            <Spin size="large" spinning={loading} />
                        )}

                        <div style={{ height: "auto", margin: "0 auto", width: "100%" }}>
                            {qrCode && (
                                <>
                                    <p style={{ marginBottom: '30px' }}>
                                        Entre no aplicativo do seu banco, aponte a câmera do seu celular para o QR Code abaixo para realizar o pagamento.
                                    </p>
                                    <h2>Valor: {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valorPagamento)}</h2>
                                    <h3>Recebedor: SAO PAULO COMERCI</h3>
                                    <h3>Instituição: BANCO INTER</h3>
                                    <h3>Código no Banco: {codigoApiBanco}</h3>
                                    <br />
                                    <div id='div-qrCode'>
                                        <QRCode
                                            size={256}
                                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                            value={qrCode}
                                            viewBox={`0 0 256 256`}
                                        />
                                    </div>
                                    <br />
                                    <p>
                                        Pix copia e cola:
                                        <Button type='default' style={{ marginLeft: '10px' }} size="small" onClick={() => {
                                            navigator.clipboard.writeText(qrCode)
                                            message.success('Pix copiado para a área de transferência!')
                                        }}>
                                            COPIAR COPIA E COLA
                                        </Button>

                                        <br />{qrCode}
                                    </p>
                                    <br />
                                    <Button type='primary' block onClick={() => {
                                        verificaStatusDoPagamento()
                                    }} loading={loading}>JÁ REALIZEI O PAGAMENTO</Button>

                                </>

                            )}

                        </div>
                    </>
                )}

            </Modal>
        </>
    )
}

export default TabelaPedidos