import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Logout = () => {
    const navigate = useNavigate()

    useEffect(() => {
        localStorage.removeItem('area26token')
        localStorage.removeItem('area26user')
        navigate('/extreme-acamp-2024/login')
    }, [])

    return (
        <></>
    )
}

export default Logout