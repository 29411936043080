import { Button, Col, ConfigProvider, Divider, Form, Modal, Row, Spin, message, theme } from 'antd'
import locale from 'antd/es/date-picker/locale/pt_BR'
import React, { useEffect } from 'react'
import Topo from './Topo'
import Rodape from './Rodape'
import { useNavigate } from 'react-router-dom'
import api from '../../services/api'
import MaskedInput from '../../components/MaskedInput'
import QRCode from "react-qr-code"
import TabelaPagamentos from './TabelaPagamentos'
import './ExtremeAcamp2024.css'
import MenuExtreme from './MenuExtreme'
import EmDesenvolvimento from './EmDesenvolvimento'
import TodasInscricoes from './TodasInscricoes'
import TabelaPedidos from './TabelaPedidos'
import Entradas from './Entradas'
import Saidas from './Saidas'
import BannerCompra from '../../assets/images/banner-compra.jpeg'

const Home = () => {
    const [userInfo, setUserInfo] = React.useState({})
    const navigate = useNavigate()
    const diaDoExtremo = new Date('2024-09-27')

    const [informacoesDePagamento, setInformacoesDePagamento] = React.useState({})
    const [showModalPagamento, setShowModalPagamento] = React.useState(false)

    const [valorPagamento, setValorPagamento] = React.useState(0)
    const [etapaPagamento, setEtapaPagamento] = React.useState(1)
    const [loading, setLoading] = React.useState(false)

    const [qrCode, setQrCode] = React.useState('')
    const [codigoApiBanco, setCodigoApiBanco] = React.useState('')
    const [cobranca, setCobranca] = React.useState({})

    const [contentIndex, setContentIndex] = React.useState('home')
    const [pedidos, setPedidos] = React.useState([])

    const gerarQrCode = async () => {
        try {
            setLoading(true)
            const response = await api.post('/pagamento/gerar-qr-code', { valor: valorPagamento })

            if (response.data?.qrCode) {
                setQrCode(response.data.qrCode)
                setCodigoApiBanco(response.data.codigoApiBanco)
                setCobranca(response.data.cobranca)
            } else {
                throw new Error('Erro ao gerar o QR Code. Tente novamente mais tarde.')
            }
        } catch (e) {
            console.log(e)
            if (e.response?.data?.message) {
                message.error(e.response.data.message)
            } else {
                message.error('Ocorreu um erro ao gerar o QR Code. Tente novamente mais tarde.')
            }
        } finally {
            setLoading(false)
        }
    }

    const verificaStatusDoPagamento = async () => {
        try {
            setLoading(true)
            const response = await api.get(`/pagamento/verificar-status/${cobranca.id}`)
            if (response.data?.status === 'CONCLUIDA') {
                message.success('Pagamento realizado com sucesso!')
                setShowModalPagamento(false)
                buscarInformacoesDePagamento()
            } else {
                message.error('O pagamento ainda não foi confirmado. Tente novamente mais tarde.')
            }
        } catch (e) {
            console.log(e)
            if (e.response?.data?.message) {
                message.error(e.response.data.message)
            } else {
                message.error('Ocorreu um erro ao verificar o status do pagamento. Tente novamente mais tarde.')
            }
        } finally {
            setLoading(false)
        }
    }


    useEffect(() => {
        document.title = 'Extreme Acamp 2024 - Início'
        setUserInfo(JSON.parse(localStorage.getItem('area26user')))

        buscarInformacoesDePagamento()
        buscarPedidos()
    }, [])

    const buscarInformacoesDePagamento = async () => {
        try {
            const response = await api.get('/user/informacoes-de-pagamento')
            setInformacoesDePagamento(response.data)
        } catch (e) {
            console.error(e)
        }
    }

    const buscarPedidos = async () => {
        try {
            const response = await api.get('/pedidos')
            setPedidos(response.data)
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <>
            <ConfigProvider theme={theme} locale={locale}>
                <Topo small />

                <div id="content-container">
                    <div id="content">
                        <div id='menu'>
                            <MenuExtreme onIndexChange={setContentIndex} />
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <h1>Olá, {userInfo?.nome?.split(' ')[0] ?? '-'}! </h1>
                            <Button type="default" onClick={() => navigate('/extreme-acamp-2024/logout')}>SAIR</Button>
                        </div>

                        <p>Você está prestes a viver o eXtremo. Faltam {Math.ceil((diaDoExtremo - new Date()) / (1000 * 60 * 60 * 24))} dias para o evento.</p>
                        <Divider style={{ color: '#3CF517', borderBlockStartColor: '#3CF517' }} />

                        {contentIndex == 'home' && (
                            <>
                                <Row gutter={12} style={{ marginTop: '30px', color: 'white' }}>
                                    <Col md={24} xs={24} style={{}}>
                                        <img src={BannerCompra} style={{ width: '100%', borderRadius: 10 }} onClick={() => navigate('/extreme-acamp-2024/produtos')} />
                                    </Col>

                                    <Divider style={{ color: '#A343FF', borderBlockStartColor: '#A343FF' }} />

                                    <Col md={24} xs={24}>
                                        <p style={{ fontSize: 18, textTransform: 'uppercase', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#A343FF', padding: '10px 0px' }}>
                                            Acompanhe o pagamento do seu acampa
                                        </p>

                                        {pedidos != null && pedidos.length > 0 && (
                                            <TabelaPedidos pedidos={pedidos} onPagamentoFinalizado={buscarPedidos} />
                                        )}

                                        {pedidos == null || pedidos.length === 0 && (
                                            <p style={{ textAlign: 'center' }}>Nenhum pedido encontrado.</p>
                                        )}
                                    </Col>
                                </Row>
                            </>
                        )}

                        {contentIndex == 'home' && userInfo.tipo == 'ADM' && (<>
                            <br />
                            {/* <p>A contagem dos pontos das equipes será exibida aqui.</p> */}
                        </>)}

                        {contentIndex == 'comprovante-de-inscricao' && (
                            <EmDesenvolvimento />
                        )}

                        {contentIndex == 'meus-dados' && (
                            <EmDesenvolvimento />
                        )}

                        {contentIndex == 'minha-equipe' && (
                            <EmDesenvolvimento />
                        )}

                        {contentIndex == 'inscricoes' && (
                            <TodasInscricoes />
                        )}

                        {contentIndex == 'entradas' && userInfo.tipo == 'ADM' && (
                            <Entradas />
                        )}

                        {contentIndex == 'saidas' && userInfo.tipo == 'ADM' && (
                            <Saidas />
                        )}

                        {contentIndex == 'inscricoes-congregacao' && (
                            <TodasInscricoes somenteCongregacao />
                        )}
                    </div>
                </div>

                <Rodape />
            </ConfigProvider>
        </>
    )
}

export default Home