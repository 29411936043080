import { Button, Col, ConfigProvider, Divider, Form, Modal, Row, Spin, message, theme } from 'antd'
import locale from 'antd/es/date-picker/locale/pt_BR'
import React, { useEffect } from 'react'
import Topo from './Topo'
import { useNavigate } from 'react-router-dom'
import { IconArrowLeft, IconTrash } from '@tabler/icons-react'
import Camiseta from '../../assets/images/camiseta.jpeg'
import Caneca from '../../assets/images/caneca-extreme.jpg'
import Bone from '../../assets/images/bone-extreme.jpg'
import ChaveiroVermelho from '../../assets/images/chaveiro-vermelho.jpeg'
import ChaveiroRoxo from '../../assets/images/chaveiro-roxo.jpeg'
import ChaveiroVerde from '../../assets/images/chaveiro-verde.jpeg'
import Rodape from './Rodape'
import api from '../../services/api'
import QRCode from 'react-qr-code'

const Produtos = () => {
    const [userInfo, setUserInfo] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [carrinho, setCarrinho] = React.useState({
        produtos: [],
        valorTotal: 0,
        formaPagamento: 'PIX'
    })
    const navigate = useNavigate()
    const [modalTamanho, setModalTamanho] = React.useState(false)
    const [carrinhoAberto, setCarrinhoAberto] = React.useState(false)
    const [modalFormaPagamento, setModalFormaPagamento] = React.useState(false)
    const [modalQrCode, setModalQrCode] = React.useState(false)

    const [qrCode, setQrCode] = React.useState('')
    const [codigoApiBanco, setCodigoApiBanco] = React.useState('')
    const [cobranca, setCobranca] = React.useState({})

    const verificaStatusDoPagamento = async () => {
        try {
            setLoading(true)
            const response = await api.get(`/pagamento/verificar-status/${cobranca.id}`)
            if (response.data?.status === 'CONCLUIDA') {
                message.success('Pagamento realizado com sucesso! Aguarde o período de confecção e entrega dos produtos.')
                setModalQrCode(false)
                setCarrinho({ produtos: [], valorTotal: 0, formaPagamento: 'PIX' })
            } else {
                message.error('O pagamento ainda não foi confirmado. Tente novamente mais tarde.')
            }
        } catch (e) {
            console.log(e)
            if (e.response?.data?.message) {
                message.error(e.response.data.message)
            } else {
                message.error('Ocorreu um erro ao verificar o status do pagamento. Tente novamente mais tarde.')
            }
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        document.title = 'Extreme Acamp 2024 - Produtos'
        setUserInfo(JSON.parse(localStorage.getItem('area26user')))
    }, [])

    const selecionarTamanhoCamisa = () => {
        setModalTamanho(true)
    }

    const salvaCarrinho = async (novoCarrinho) => {
        try {
            setLoading(true)
            const response = await api.post('/carrinhos', novoCarrinho)

            if (novoCarrinho.formaPagamento === 'PIX') {
                message.success('Pedido salvo com sucesso! Faça o seu pagamento agora mesmo pelo PIX')
                if (response.data?.meuPagamento?.qrcode) {
                    setQrCode(response.data.meuPagamento.qrcode)
                    setCodigoApiBanco(response.data.meuPagamento.codigoApiBanco)
                    setCobranca(response.data.meuPagamento)
                    setModalQrCode(true)
                } else {
                    setQrCode('')
                    setCodigoApiBanco('')
                    setCobranca({})
                    throw new Error('Erro ao gerar o QR Code. Tente novamente mais tarde.')
                    setCarrinho({ produtos: [], valorTotal: 0, formaPagamento: 'PIX' })
                }
            } else {
                message.success('Pedido salvo com sucesso! Entraremos em contato com você para você realizar seu pagamento em dinheiro ou cartão.')
                setCarrinho({ produtos: [], valorTotal: 0, formaPagamento: 'PIX' })
            }
        } catch (e) {
            console.log(e)
            message.error('Ocorreu um erro ao salvar o pedido. Tente novamente mais tarde.')
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Modal
                title='PAGAR NO QRCODE'
                open={modalQrCode}
                footer={null}
                onClose={() => { setModalQrCode(false) }}
                onCancel={() => { setModalQrCode(false) }}
            >
                {qrCode && (
                    <>
                        <p style={{ marginBottom: '30px' }}>
                            Entre no aplicativo do seu banco, aponte a câmera do seu celular para o QR Code abaixo para realizar o pagamento.
                        </p>
                        <h2>Valor: {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(carrinho.valorTotal)}</h2>
                        <h3>Recebedor: SAO PAULO COMERCI</h3>
                        <h3>Instituição: BANCO INTER</h3>
                        <h3>Código no Banco: {codigoApiBanco}</h3>
                        <br />
                        <div id='div-qrCode'>
                            <QRCode
                                size={256}
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                value={qrCode}
                                viewBox={`0 0 256 256`}
                            />
                        </div>
                        <br />
                        <p>
                            Pix copia e cola:
                            <Button type='default' style={{ marginLeft: '10px' }} size="small" onClick={() => {
                                navigator.clipboard.writeText(qrCode)
                                message.success('Pix copiado para a área de transferência!')
                            }}>
                                COPIAR COPIA E COLA
                            </Button>

                            <br />{qrCode}
                        </p>
                        <br />
                        <Button type='primary' block onClick={() => {
                            verificaStatusDoPagamento()
                        }} loading={loading}>JÁ REALIZEI O PAGAMENTO</Button>

                    </>

                )}
            </Modal>
            <Modal
                title='SELECIONAR FORMA DE PAGAMENTO'
                open={modalFormaPagamento}
                footer={null}
                onClose={() => { setModalFormaPagamento(false) }}
                onCancel={() => { setModalFormaPagamento(false) }}
            >
                <Button style={{ marginBottom: '10px' }} onClick={() => {
                    setModalFormaPagamento(false)
                    const novoCarrinho = { ...carrinho, formaPagamento: 'PIX' };
                    setCarrinho(novoCarrinho)
                    salvaCarrinho(novoCarrinho)
                }} type="primary" block>VOU PAGAR NO PIX AGORA</Button>

                <Button style={{ marginBottom: '10px' }} onClick={() => {
                    setModalFormaPagamento(false)
                    const novoCarrinho = { ...carrinho, formaPagamento: 'DIN' };
                    setCarrinho(novoCarrinho)
                    salvaCarrinho(novoCarrinho)
                }} block>VOU PAGAR NO DINHEIRO DEPOIS</Button>

                <Button style={{ marginBottom: '10px' }} onClick={() => {
                    setModalFormaPagamento(false)
                    const novoCarrinho = { ...carrinho, formaPagamento: 'CAR' };
                    setCarrinho(novoCarrinho)
                    salvaCarrinho(novoCarrinho)
                }} block>VOU PAGAR NO CARTÃO DEPOIS</Button>

            </Modal>

            <Modal
                title='SELECIONAR TAMANHO CAMISA'
                open={modalTamanho}
                footer={null}
                onClose={() => { setModalTamanho(false) }}
                onCancel={() => { setModalTamanho(false) }}
            >

                <Button style={{ marginBottom: '10px' }} onClick={() => {
                    setCarrinho({ produtos: [...carrinho.produtos, { nome: '2º Lote Camisa Extreme Tamanho PP', valor: 65 }], valorTotal: carrinho.valorTotal + 65 })
                    setModalTamanho(false)
                }} type="primary" block>PP</Button>
                <Button style={{ marginBottom: '10px' }} onClick={() => {
                    setCarrinho({ produtos: [...carrinho.produtos, { nome: '2º Lote Camisa Extreme Tamanho P', valor: 65 }], valorTotal: carrinho.valorTotal + 65 })
                    setModalTamanho(false)
                }} type="primary" block>P</Button>
                <Button style={{ marginBottom: '10px' }} onClick={() => {
                    setCarrinho({ produtos: [...carrinho.produtos, { nome: '2º Lote Camisa Extreme Tamanho M', valor: 65 }], valorTotal: carrinho.valorTotal + 65 })
                    setModalTamanho(false)
                }} type="primary" block>M</Button>
                <Button style={{ marginBottom: '10px' }} onClick={() => {
                    setCarrinho({ produtos: [...carrinho.produtos, { nome: '2º Lote Camisa Extreme Tamanho G', valor: 65 }], valorTotal: carrinho.valorTotal + 65 })
                    setModalTamanho(false)
                }} type="primary" block>G</Button>
                <Button style={{ marginBottom: '10px' }} onClick={() => {
                    setCarrinho({ produtos: [...carrinho.produtos, { nome: '2º Lote Camisa Extreme Tamanho GG', valor: 65 }], valorTotal: carrinho.valorTotal + 65 })
                    setModalTamanho(false)
                }} type="primary" block>GG</Button>
                <Button style={{ marginBottom: '10px' }} onClick={() => {
                    setCarrinho({ produtos: [...carrinho.produtos, { nome: '2º Lote Camisa Extreme Tamanho G1', valor: 65 }], valorTotal: carrinho.valorTotal + 65 })
                    setModalTamanho(false)
                }} type="primary" block>G1</Button>
                <Button style={{ marginBottom: '10px' }} onClick={() => {
                    setCarrinho({ produtos: [...carrinho.produtos, { nome: '2º Lote Camisa Extreme Tamanho G3', valor: 65 }], valorTotal: carrinho.valorTotal + 65 })
                    setModalTamanho(false)
                }} type="primary" block>G3</Button>
                <Button style={{ marginBottom: '10px' }} onClick={() => {
                    setCarrinho({ produtos: [...carrinho.produtos, { nome: '2º Lote Camisa Extreme Tamanho G5', valor: 65 }], valorTotal: carrinho.valorTotal + 65 })
                    setModalTamanho(false)
                }} type="primary" block>G5</Button>
            </Modal>
            <ConfigProvider theme={theme} locale={locale}>
                <Topo small />

                <div id="content-container">
                    <div id="content">
                        <Button onClick={() => { window.history.back() }}>
                            <IconArrowLeft />
                        </Button>

                        <Divider />

                        {loading ? <Spin /> : (
                            <>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <h1>Olá, {userInfo?.nome?.split(' ')[0] ?? '-'}! </h1>
                                    <Button type="default" onClick={() => navigate('/extreme-acamp-2024/logout')}>SAIR</Button>
                                </div>

                                <Divider style={{ color: '#3CF517', borderBlockStartColor: '#3CF517' }} />

                                <Row gutter={24}>
                                    <Col md={8} xs={24} style={{ marginBottom: '50px' }}>
                                        <div className="produto" style={{ textAlign: 'center' }}>
                                            <img src={Camiseta} style={{ width: '100%', borderRadius: 10 }} />
                                            <h2 style={{ margin: '10px 0px ' }}>2º Lote Camisa Extreme</h2>
                                            <p style={{ fontSize: '30px', fontWeight: 'bold', marginBottom: '20px' }}>R$ 65,00</p>
                                            <Button disabled type="primary" block onClick={selecionarTamanhoCamisa}>SELECIONAR O TAMANHO</Button>
                                        </div>
                                    </Col>

                                    <Col md={8} xs={24} style={{ marginBottom: '50px' }}>
                                        <div className="produto" style={{ textAlign: 'center' }}>
                                            <img src={Caneca} style={{ width: '100%', borderRadius: 10 }} />
                                            <h2 style={{ margin: '10px 0px ' }}>Caneca Extreme</h2>
                                            <p style={{ fontSize: '30px', fontWeight: 'bold', marginBottom: '20px' }}>R$ 40,00</p>
                                            <Button disabled type="primary" block onClick={() => {
                                                setCarrinho({ produtos: [...carrinho.produtos, { nome: 'Caneca Extreme', valor: 40 }], valorTotal: carrinho.valorTotal + 40 })
                                            }}>ADICIONAR AO CARRINHO</Button>
                                        </div>
                                    </Col>

                                    <Col md={8} xs={24} style={{ marginBottom: '50px' }}>
                                        <div className="produto" style={{ textAlign: 'center' }}>
                                            <img src={Bone} style={{ width: '100%', borderRadius: 10 }} />
                                            <h2 style={{ margin: '10px 0px ' }}>Boné Extreme</h2>
                                            <p style={{ fontSize: '30px', fontWeight: 'bold', marginBottom: '20px' }}>R$ 25,00</p>
                                            <Button disabled type="primary" block onClick={() => {
                                                setCarrinho({ produtos: [...carrinho.produtos, { nome: 'Boné Extreme', valor: 25 }], valorTotal: carrinho.valorTotal + 25 })
                                            }}>ADICIONAR AO CARRINHO</Button>
                                        </div>
                                    </Col>

                                    <Col md={8} xs={24} style={{ marginBottom: '50px' }}>
                                        <div className="produto" style={{ textAlign: 'center' }}>
                                            <img src={ChaveiroVermelho} style={{ width: '100%', borderRadius: 10 }} />
                                            <h2 style={{ margin: '10px 0px ' }}>Chaveiro Extreme Vermelho</h2>
                                            <p style={{ fontSize: '30px', fontWeight: 'bold', marginBottom: '20px' }}>R$ 7,00</p>
                                            <Button type="primary" block onClick={() => {
                                                setCarrinho({ produtos: [...carrinho.produtos, { nome: 'Chaveiro Extreme Vermelho', valor: 7 }], valorTotal: carrinho.valorTotal + 7 })
                                            }}>ADICIONAR AO CARRINHO</Button>
                                        </div>
                                    </Col>

                                    <Col md={8} xs={24} style={{ marginBottom: '50px' }}>
                                        <div className="produto" style={{ textAlign: 'center' }}>
                                            <img src={ChaveiroRoxo} style={{ width: '100%', borderRadius: 10 }} />
                                            <h2 style={{ margin: '10px 0px ' }}>Chaveiro Extreme Roxo</h2>
                                            <p style={{ fontSize: '30px', fontWeight: 'bold', marginBottom: '20px' }}>R$ 7,00</p>
                                            <Button type="primary" block onClick={() => {
                                                setCarrinho({ produtos: [...carrinho.produtos, { nome: 'Chaveiro Extreme Roxo', valor: 7 }], valorTotal: carrinho.valorTotal + 7 })
                                            }}>ADICIONAR AO CARRINHO</Button>
                                        </div>
                                    </Col>

                                    <Col md={8} xs={24} style={{ marginBottom: '50px' }}>
                                        <div className="produto" style={{ textAlign: 'center' }}>
                                            <img src={ChaveiroVerde} style={{ width: '100%', borderRadius: 10 }} />
                                            <h2 style={{ margin: '10px 0px ' }}>Chaveiro Extreme Verde</h2>
                                            <p style={{ fontSize: '30px', fontWeight: 'bold', marginBottom: '20px' }}>R$ 7,00</p>
                                            <Button type="primary" block onClick={() => {
                                                setCarrinho({ produtos: [...carrinho.produtos, { nome: 'Chaveiro Extreme Verde', valor: 7 }], valorTotal: carrinho.valorTotal + 7 })
                                            }}>ADICIONAR AO CARRINHO</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </>

                        )}

                    </div>
                </div>

                {carrinho.produtos.length > 0 && (
                    <>
                        <div id='carrinho' style={{
                            backgroundColor: '#3CF517',
                            minHeight: carrinhoAberto ? '200px' : '50px',
                            position: 'fixed',
                            bottom: 0,
                            width: 'calc(100% - 20px)',
                            padding: '20px 10px 20px 10px',
                        }}>
                            <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
                                {carrinhoAberto && (
                                    <>
                                        <h4>
                                            TOTAL DO SEU PEDIDO: R$ {carrinho.valorTotal.toFixed(2)}
                                            <IconTrash style={{ marginRight: '30px', float: 'right' }} onClick={() => {
                                                setCarrinho({ produtos: [], valorTotal: 0 })
                                            }} color='red' />
                                        </h4>
                                        <div id='itens-detalhados'>
                                            {carrinho.produtos.map((produto, index) => (
                                                <div key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: '20px' }}>
                                                    <p>{produto.nome}</p>
                                                    <p>R$ {produto.valor?.toFixed(2)}</p>
                                                </div>
                                            ))}
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: '20px' }}>
                                            <Button block onClick={() => {
                                                setCarrinhoAberto(false)
                                            }} style={{ width: '45%' }}>OCULTAR ITENS</Button>
                                            <Button block type="primary" onClick={() => { setModalFormaPagamento(true); setCarrinhoAberto(false) }} style={{ width: '45%' }}>FECHAR PEDIDO</Button>
                                        </div>
                                    </>
                                )}

                                {!carrinhoAberto && (
                                    <>
                                        <h4>
                                            TOTAL DO SEU PEDIDO: R$ {carrinho.valorTotal.toFixed(2)}
                                            <IconTrash style={{ marginRight: '30px', float: 'right' }} onClick={() => {
                                                setCarrinho({ produtos: [], valorTotal: 0 })
                                            }} color='red' />
                                        </h4>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: '20px' }}>
                                            <Button block onClick={() => {
                                                setCarrinhoAberto(true)
                                            }} style={{ width: '45%' }}>DETALHAR ITENS</Button>
                                            <Button block type="primary" onClick={() => { setModalFormaPagamento(true); setCarrinhoAberto(false) }} style={{ width: '45%' }}>FECHAR PEDIDO</Button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                )}

                <Rodape />
            </ConfigProvider>
        </>
    )
}

export default Produtos