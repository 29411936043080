import { Button, Col, ConfigProvider, DatePicker, Divider, Form, Input, Modal, Row, Select, Spin, message, theme } from 'antd'
import locale from 'antd/es/date-picker/locale/pt_BR'
import React, { useEffect } from 'react'
import Topo from './Topo'
import Rodape from './Rodape'
import api from '../../services/api'
import { IconArrowLeft, IconSignLeft } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'

const FormularioEntrada = () => {
    const [jovens, setJovens] = React.useState([])
    const [pedidos, setPedidos] = React.useState([])
    const [entrada, setEntrada] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        loadJovens()
    }, [])

    useEffect(() => {
        if (entrada?.usuarioId) {
            loadPedidos()
        }
    }, [entrada.usuarioId])

    const loadJovens = async () => {
        try {
            const response = await api.get('/user/todos-os-usuarios')
            setJovens(response.data ?? [])
        } catch (e) {
            message.error('Ocorreu um erro ao carregar os jovens. Tente novamente mais tarde.')
        }
    }

    const loadPedidos = async () => {
        try {
            const response = await api.get('/user/pedidos-do-usuario/' + entrada.usuarioId)
            setPedidos(response.data ?? [])
        } catch (e) {
            message.error('Ocorreu um erro ao carregar os pedidos. Tente novamente mais tarde.')
        }
    }

    const salvaEntrada = async () => {
        try {

            if (!entrada.usuarioId || !entrada.pedidoId || !entrada.dataPagamento || !entrada.formaPagamento || !entrada.valor) {
                message.error('Preencha todos os campos antes de salvar a entrada.')
                return
            }

            setLoading(true)
            await api.post('/entradas', entrada)
            message.success('Entrada salva com sucesso!')
            // navigate('/extreme-acamp-2024/home')
        } catch (e) {
            message.error('Ocorreu um erro ao salvar a entrada. Tente novamente mais tarde.')
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <ConfigProvider theme={theme} locale={locale}>
                <Topo small />

                <div id="content-container">
                    <div id="content">
                        <Button onClick={() => { window.history.back() }}>
                            <IconArrowLeft/>
                        </Button>

                        <Divider />

                        {loading ? <Spin /> : (
                            <>
                                <Row gutter={12}>
                                    <Col md={8} xs={24}>
                                        <Select
                                            showSearch
                                            placeholder="Selecione um jovem"
                                            optionFilterProp="label"
                                            onChange={(value) => {
                                                setEntrada({ ...entrada, usuarioId: value })
                                            }}
                                            onSearch={(value) => { console.log(value) }}
                                            options={jovens.map(jovem => ({ value: jovem.id, label: jovem.nome }))}
                                            style={{ width: '100%' }}
                                        />
                                    </Col>

                                    <Col md={16} xs={24}>
                                        <Select
                                            showSearch
                                            placeholder="Selecione o pedido"
                                            optionFilterProp="label"
                                            onChange={(value) => {
                                                setEntrada({ ...entrada, pedidoId: value })
                                            }}
                                            onSearch={(value) => { console.log(value) }}
                                            options={pedidos.map(pedido => ({ value: pedido.id, label: `${pedido.produto} - R$ ${pedido.valorTotal} - Pago: R$ ${pedido.valorPago}` }))}
                                            style={{ width: '100%' }}
                                        />
                                    </Col>

                                    <Col md={6} xs={24} style={{ marginTop: '20px' }}>
                                        <DatePicker
                                            format={{
                                                format: 'DD/MM/YYYY',
                                                type: 'mask',
                                            }}
                                            style={{ width: '100%' }}
                                            placeholder="Data de Pagamento"
                                            onChange={(date, dateString) => {
                                                setEntrada({ ...entrada, dataPagamento: dateString })
                                            }}
                                        />
                                    </Col>

                                    <Col md={8} xs={24} style={{ marginTop: '20px' }}>
                                        <Select
                                            showSearch
                                            placeholder="Forma de Pagamento"
                                            optionFilterProp="label"
                                            onChange={(value) => {
                                                setEntrada({ ...entrada, formaPagamento: value })
                                            }}
                                            onSearch={(value) => { console.log(value) }}
                                            options={[
                                                { value: 'DIN', label: 'Dinheiro' },
                                                { value: 'CAR', label: 'Cartão' },
                                                { value: 'PIX', label: 'PIX' },
                                            ]}
                                            style={{ width: '100%' }}
                                        />
                                    </Col>

                                    <Col md={10} xs={24} style={{ marginTop: '20px' }}>
                                        <Input
                                            placeholder="Valor"
                                            onChange={(e) => {
                                                setEntrada({ ...entrada, valor: e.target.value })
                                            }}
                                        />
                                    </Col>
                                </Row>

                                <Divider style={{ color: '#3CF517', borderBlockStartColor: '#3CF517', marginTop: '50px' }} />

                                <Button type="primary" onClick={() => { salvaEntrada() }} block style={{ marginTop: '50px' }}>SALVAR</Button>
                            </>
                        )}
                    </div>
                </div>

                <Rodape />
            </ConfigProvider>
        </>
    )
}

export default FormularioEntrada