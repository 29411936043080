import React, { useEffect } from 'react'

import faixa1 from '../../assets/images/faixa-1.png'
import faixa2 from '../../assets/images/faixa-2.png'
import mochila from '../../assets/images/mochila.png'
import biblia from '../../assets/images/biblia.png'
import paz from '../../assets/images/paz.png'


import './ExtremeAcamp2024.css'
import { IconCalendar, IconCashBanknote, IconMapPin } from '@tabler/icons-react'
import Topo from './Topo'
import { Link, useNavigate } from 'react-router-dom'
import Rodape from './Rodape'

const ExtremeAcamp2024 = () => {
    const navigate = useNavigate()

    useEffect(() => {
        document.title = 'Extreme Acamp 2024'

        const hasToken = localStorage.getItem('area26token')
        if (hasToken) {
            navigate('/extreme-acamp-2024/home')
        }
    }, [])

    return (
        <>
            <Topo botoes={(
                <div className="botoes">
                    <Link to="/extreme-acamp-2024/inscrever-se" className='botao botaoInscrever' style={{ marginRight: '20px' }}>
                        INSCREVER-SE
                    </Link>
                    <Link to="/extreme-acamp-2024/login" className='botao botaoLogin'>
                        FAZER LOGIN
                    </Link>
                </div>
            )} />

            <div id="espaco-video">
                <iframe
                    id='video-fake'
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/m98_PUZeOKQ?si=zcEfCrexxJC5YQxJ"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                ></iframe>
            </div>

            <div id="itens-elementos-container">
                <div id="itens-elementos">
                    <img src={mochila} alt="Mochila" id='img-mochila' />
                    <img src={biblia} alt="Bíblia" id='img-biblia' />
                    <img src={paz} alt="Paz" id='img-paz' />
                </div>
            </div>

            <div id="faixa3" className="faixa">
                <img src={faixa2} alt="Faixa 3" />
            </div>

            <div id="sobre-o-acampa-container">
                <div id="sobre-o-acampa-texto">
                    <p>Venha fazer parte de uma experiência única e transformadora no Extreme acampa 2024, com o tema: <b class='laranja'>O GRITO</b> baseado em <b class='amarelo'>Isaias 42.12-13</b>!</p><br /><br />

                    <p>O nosso acampamento é um momento de comunhão, diversão e aprendizado onde jovens como você se reúnem para fortalecer a fé, criar laços de
                        amizade e se divertir em meio à natureza.
                    </p><br /><br />

                    <p>No acampamento, teremos atividades emocionantes como trilhas, jogos, música, palestras inspiradoras e momentos de oração que vão renovar
                        o seu espírito e te <b class='verde'>conectar com Deus</b>. Além disso, teremos momentos de descontração e convivência, onde você poderá compartilhar experiências,
                        fazer novos amigos e criar lembranças inesquecíveis.
                    </p><br /><br />

                    <p>Este é o momento perfeito para você se desconectar da correria do dia a dia, se reconectar consigo mesmo e com <b class='laranja'>Deus</b>, e viver dias de alegria, paz e gratidão.
                        Não deixe essa oportunidade passar!</p><br /><br />

                    <p class='roxo' style={{ textAlign: 'center' }}><b>VENHA PARA O EXTREMO!</b></p>
                </div>
            </div>

            <div id="faixa4" className="faixa">
                <img src={faixa1} alt="Faixa 4" />
            </div>

            <div className="data-e-valor-container">
                <div className="data-e-valor">
                    <div className="data">
                        <div>
                            <IconCalendar size={50} /><br />
                            27, 28 e 29 de <br />Setembro de 2024
                        </div>
                    </div>

                    <div className="local">
                        <div>
                            <IconMapPin size={50} /><br />
                            Chácara Paraiso Verde <br />
                            <small>Km 13, Iranduba-AM</small>
                        </div>
                    </div>

                    <div className="valor">
                        <div>
                            <IconCashBanknote size={50} /><br />
                            R$ 200,00
                            <small style={{ display: 'block' }}>
                                <i>R$ 55,00 camisa - opcional</i>
                            </small>
                        </div>
                    </div>
                </div>

                <div id="localizacao">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13398.813587132143!2d-60.211587811646105!3d-3.2271899407046125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x926c0b2add2dbead%3A0xcd9a2eaa280a66d8!2sCh%C3%A1cara%20Para%C3%ADso%20Verde!5e0!3m2!1spt-BR!2sbr!4v1716900112592!5m2!1spt-BR!2sbr"
                        width="600"
                        height="450"
                        style={{ border: 0 }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                    >
                    </iframe>
                </div>
            </div>

            <Rodape/>
        </>
    )
}

export default ExtremeAcamp2024