import { Button, Col, ConfigProvider, Divider, Form, Input, Row, message } from 'antd'
import locale from 'antd/es/date-picker/locale/pt_BR'
import React, { useEffect } from 'react'
import Topo from './Topo'
import Rodape from './Rodape'
import './ExtremeAcamp2024.css'
import theme from '../../services/theme'
import api from '../../services/api'
import { useNavigate, useParams } from 'react-router-dom'
import MaskedInput from '../../components/MaskedInput'

const NovaSenha = () => {
    const [novaSenha, setNovaSenha] = React.useState('')
    const [repeteNovaSenha, setRepeteNovaSenha] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const navigate = useNavigate()
    const { usuario, codigo } = useParams()

    const atualizarSenha = async () => {
        try {

            if (novaSenha.length < 6) {
                throw new Error('A senha deve ter no mínimo 6 caracteres')
            }

            if (novaSenha !== repeteNovaSenha) {
                throw new Error('As senhas não coincidem')
            }

            setLoading(true)

            await api.post('/auth/atualizar-senha', {
                novaSenha, repeteNovaSenha, usuario, codigo
            })

            message.success(`Senha atualizada com sucesso!`)

            setTimeout(() => {
                navigate('/extreme-acamp-2024/login')
            }, 3000)
        } catch (e) {
            if (e?.response?.data?.errors) {
                e.response.data.errors.forEach(error => {
                    message.error(error.msg)
                })
            } else if (e?.response?.data?.message) {
                message.error(e.response.data.message)
            } else {
                message.error(e.message)
            }
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <ConfigProvider theme={theme} locale={locale}>
                <Topo small />

                <div id="content-container">
                    <div id="content">
                        <h1>Atualizar Senha</h1>
                        <p>Você clicou no link de recuperação de senha. Preencha o formulário abaixo para atualizar sua senha</p>
                        <Divider style={{ color: '#3CF517', borderBlockStartColor: '#3CF517' }} />

                        <Form layout='vertical'>
                            <Row gutter={12} style={{ marginTop: '80px', color: 'white' }}>
                                <Col md={{ span: 8, offset: 8 }} xs={24}>
                                    <Form.Item label='Digite a Nova Senha' required>
                                        <Input.Password value={novaSenha} onChange={e => setNovaSenha(e.target.value)} />
                                    </Form.Item>
                                </Col>

                                <Col md={{ span: 8, offset: 8 }} xs={24}>
                                    <Form.Item label='Repetir Nova Senha' required>
                                        <Input.Password value={repeteNovaSenha} onChange={e => setRepeteNovaSenha(e.target.value)} />
                                    </Form.Item>
                                </Col>

                                <Col md={{ span: 8, offset: 8 }} xs={24} style={{ marginTop: '30px' }}>
                                    <Form.Item>
                                        <Button
                                            type='primary'
                                            htmlType='submit'
                                            block
                                            onClick={atualizarSenha}
                                            loading={loading}
                                        >Atualizar Senha</Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>

                <Rodape />
            </ConfigProvider>
        </>
    )
}

export default NovaSenha