import { Button, Col, ConfigProvider, Divider, Form, Input, Row, message } from 'antd'
import locale from 'antd/es/date-picker/locale/pt_BR'
import React, { useEffect } from 'react'
import Topo from './Topo'
import Rodape from './Rodape'
import './ExtremeAcamp2024.css'
import theme from '../../services/theme'
import api from '../../services/api'
import { useNavigate } from 'react-router-dom'

const Login = () => {
    const [email, setEmail] = React.useState('')
    const [senha, setSenha] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        document.title = 'Extreme Acamp 2024 - Login'
    }, [])

    const fazerLogin = async () => {
        try {
            setLoading(true)

            const response = await api.post('/auth/login', { email, senha })
            if (response.data.token) {
                localStorage.setItem('area26token', response.data.token)
                localStorage.setItem('area26user', JSON.stringify(response.data.user))

                message.success('Login realizado com sucesso!')
                navigate('/extreme-acamp-2024/home')
            } else {
                throw new Error('Erro ao realizar o login. Tente novamente mais tarde.')
            }

        } catch (e) {
            if (e.response?.data?.message) {
                message.error(e.response.data.message)
            } else {
                message.error('Ocorreu um erro ao fazer login')
            }
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <ConfigProvider theme={theme} locale={locale}>
                <Topo small/>

                <div id="content-container">
                    <div id="content">
                        <h1>Formulário de Login </h1>
                        <p>Faça login para entrar no eXtremo</p>
                        <Divider style={{ color: '#3CF517', borderBlockStartColor: '#3CF517' }} />

                        <Form layout='vertical'>
                            <Row gutter={12} style={{ marginTop: '80px', color: 'white' }}>
                                <Col md={{ span: 8, offset: 8 }} xs={24}>
                                    <Form.Item label='E-mail' required>
                                        <Input value={email} onChange={e => setEmail(e.target.value)} />
                                    </Form.Item>
                                </Col>

                                <Col md={{span: 8, offset: 8 }} xs={24}>
                                    <Form.Item label='Senha' required>
                                        <Input.Password value={senha} onChange={e => setSenha(e.target.value)} />
                                    </Form.Item>
                                </Col>

                                <Col md={{span: 8, offset: 8 }} xs={24}>
                                    <Form.Item>
                                        <Button 
                                            type='link' 
                                            block
                                            onClick={() => navigate('/extreme-acamp-2024/recuperar-senha')}
                                        >
                                            Esqueci minha senha
                                        </Button>
                                    </Form.Item>
                                </Col>

                                <Col md={{span: 8, offset: 8}} xs={24} style={{ marginTop: '30px' }}>
                                    <Form.Item>
                                        <Button type='primary' htmlType='submit' block onClick={fazerLogin} loading={loading}>Entrar</Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>

                <Rodape />
            </ConfigProvider>
        </>
    )
}

export default Login