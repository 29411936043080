import { IconBounceLeft, IconBounceRight, IconChecklist, IconHome, IconUserSquare, IconUsersGroup } from '@tabler/icons-react'
import { Menu } from 'antd'
import React, { useEffect, useState } from 'react'

const MenuExtreme = ( { onIndexChange }) => {
    const [itens, setItens] = useState([])
    const [current, setCurrent] = useState('home');
    const [tipoMenu, setTipoMenu] = useState('horizontal')

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('area26user'))
        if (!userData) return

        let meusItensMenu = [{
            label: 'Início',
            key: 'home',
            icon: <IconHome style={{ paddingTop: '10px'}}/>,
        }]

        if (userData.tipo == 'ADM') {
            /* Líderes de jovens na área */
            meusItensMenu.push({
                label: 'Inscrições',
                key: 'inscricoes',
                icon: <IconChecklist style={{ paddingTop: '10px'}}/>,
            })

            meusItensMenu.push({
                label: 'Entradas',
                key: 'entradas',
                icon: <IconBounceLeft style={{ paddingTop: '10px'}}/>,
            })

            meusItensMenu.push({
                label: 'Saídas',
                key: 'saidas',
                icon: <IconBounceRight style={{ paddingTop: '10px'}}/>,
            })


        } else if (userData.tipo == 'PAR') {
            /* Participantes */
            meusItensMenu.push({
                label: 'Comprovante de Inscrição',
                key: 'comprovante-de-inscricao',
                icon: <IconChecklist style={{ paddingTop: '10px'}}/>,
            })

            meusItensMenu.push({
                label: 'Meus Dados',
                key: 'meus-dados',
                icon: <IconUserSquare style={{ paddingTop: '10px'}}/>,
            })

            meusItensMenu.push({
                label: 'Minha Equipe',
                key: 'minha-equipe',
                icon: <IconUsersGroup style={{ paddingTop: '10px'}}/>,
            })

        } else if (userData.tipo == 'ORG') {
            /* Líderes de jovens nas congregações */

            meusItensMenu.push({
                label: 'Inscrições',
                key: 'inscricoes-congregacao',
                icon: <IconChecklist style={{ paddingTop: '10px'}}/>,
            })

            meusItensMenu.push({
                label: 'Comprovante de Inscrição',
                key: 'comprovante-de-inscricao',
                icon: <IconChecklist style={{ paddingTop: '10px'}}/>,
            })

            meusItensMenu.push({
                label: 'Meus Dados',
                key: 'meus-dados',
                icon: <IconUserSquare style={{ paddingTop: '10px'}}/>,
            })
        }

        setItens(meusItensMenu)
    }, [])

    const handleMenuClick = (e) => {
        setCurrent(e.key)
        onIndexChange(e.key)
    }

    return (
        <>
            <div style={{ marginBottom: '50px' }}>
                <Menu
                    onClick={handleMenuClick}
                    selectedKeys={[current]}
                    mode={tipoMenu}
                    items={itens}
                    theme="dark"
                />
            </div>

        </>
    )
}

export default MenuExtreme