const theme = {
  token: {},
  components: {
    Form: {
      labelColor: '#fff'
    },
    Input: {
      borderColor: '#fff',
      activeBorderColor: '#fff',
      hoverBorderColor: '#fff',
      navArrowColor: '#fff',
    },
    Steps: {

    }
  }
} 

export default theme