import { createBrowserRouter } from "react-router-dom";
import App from "./pages/App";
import ExtremeAcamp2024 from "./pages/ExtremeAcamp2024/ExtremeAcamp2024";
import InscreverSe from "./pages/ExtremeAcamp2024/InscreverSe";
import Home from "./pages/ExtremeAcamp2024/Home";
import ValidateLogin from "./pages/ExtremeAcamp2024/ValidateLogin";
import Login from "./pages/ExtremeAcamp2024/Login";
import Logout from "./pages/ExtremeAcamp2024/Logout";
import RecuperarSenha from "./pages/ExtremeAcamp2024/RecuperarSenha";
import NovaSenha from "./pages/ExtremeAcamp2024/NovaSenha";
import FormularioEntrada from "./pages/ExtremeAcamp2024/FormularioEntrada";
import Produtos from "./pages/ExtremeAcamp2024/Produtos";

const router = createBrowserRouter([
  { path: "/", element: (<App />), },

  { path: "/extreme-acamp-2024", element: (<ExtremeAcamp2024 />), },
  { path: "/extreme-acamp-2024/inscrever-se", element: (<InscreverSe />), },
  { path: "/extreme-acamp-2024/login", element: (<Login/>), },
  { path: "/extreme-acamp-2024/recuperar-senha", element: (<RecuperarSenha/>), },
  { path: "/extreme-acamp-2024/recuperar-senha/:usuario/:codigo", element: (<NovaSenha/>), },
  
  { path: "/extreme-acamp-2024/home", element: (<ValidateLogin><Home/></ValidateLogin>), },
  { path: "/extreme-acamp-2024/logout", element: (<ValidateLogin><Logout/></ValidateLogin>), },

  { path: "/extreme-acamp-2024/entradas/nova", element: (<ValidateLogin><FormularioEntrada/></ValidateLogin>), },
  { path: "/extreme-acamp-2024/produtos", element: (<ValidateLogin><Produtos/></ValidateLogin>), },

]);

export default router;