import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const ValidateLogin = ({ children }) => {
    const navigate = useNavigate()

    useEffect(() => {
        const token = localStorage.getItem('area26token')

        if (!token) {
            navigate('/extreme-acamp-2024')
        }
    })

    return (
        <>
            {children}
        </>
    )
}

export default ValidateLogin