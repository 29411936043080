import { Button, Col, ConfigProvider, Divider, Form, Input, Row, message } from 'antd'
import locale from 'antd/es/date-picker/locale/pt_BR'
import React, { useEffect } from 'react'
import Topo from './Topo'
import Rodape from './Rodape'
import './ExtremeAcamp2024.css'
import theme from '../../services/theme'
import api from '../../services/api'
import { useNavigate } from 'react-router-dom'
import MaskedInput from '../../components/MaskedInput'

const RecuperarSenha = () => {
    const [email, setEmail] = React.useState('')
    const [cpf, setCpf] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const navigate = useNavigate()
    const [block, setBlock] = React.useState(false)

    const recuperarSenha = async () => {
        try {
            setLoading(true)

            await api.post('/auth/recuperar-senha', {
                email, cpf
            })

            message.success(`E-mail de recuperação de senha enviado com sucesso! 
                Por favor, verifique sua caixa de entrada e clique no link para redefinir sua senha. 
                Verifique também a caixa de spam.`, 15)

            setBlock(true)
        } catch (e) {
            if (e?.response?.data?.errors) {
                e.response.data.errors.forEach(error => {
                    message.error(error.msg)
                })
            } else if (e?.response?.data?.message) {
                message.error(e.response.data.message)
            } else {
                message.error('Erro ao enviar os dados. Tente novamente mais tarde.')
            }
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <ConfigProvider theme={theme} locale={locale}>
                <Topo small />

                <div id="content-container">
                    <div id="content">
                        <h1>Recuperação de Senha</h1>
                        <p>Preencha o formulário abaixo para recuperar sua senha</p>
                        <Divider style={{ color: '#3CF517', borderBlockStartColor: '#3CF517' }} />

                        <Form layout='vertical'>
                            <Row gutter={12} style={{ marginTop: '80px', color: 'white' }}>
                                <Col md={{ span: 8, offset: 8 }} xs={24}>
                                    <Form.Item label='Seu e-mail' required>
                                        <Input value={email} onChange={e => setEmail(e.target.value)} />
                                        <small style={{ color: 'white' }}>Use o mesmo email usado na sua inscrção.</small>
                                    </Form.Item>
                                </Col>

                                <Col md={{ span: 8, offset: 8 }} xs={24}>
                                    <Form.Item label='Seu CPF' required>
                                        <MaskedInput mask='000.000.000-00' value={cpf} onChange={e => setCpf(e.target.value)} />
                                    </Form.Item>
                                </Col>

                                <Col md={{ span: 8, offset: 8 }} xs={24} style={{ marginTop: '30px' }}>
                                    <Form.Item>
                                        <Button 
                                            type='primary' 
                                            htmlType='submit' 
                                            block 
                                            onClick={recuperarSenha} 
                                            loading={loading}
                                            disabled={block}
                                        >Recuperar Senha</Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>

                <Rodape />
            </ConfigProvider>
        </>
    )
}

export default RecuperarSenha