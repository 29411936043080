import { Card, Col, Row, Statistic, Table, Tabs, Tag, message } from 'antd';
import React, { useEffect, useState } from 'react'
import api from '../../services/api';

const TabelaCongregacao = ({ congregacao, inscricoes, loading }) => {
    const [totalInscritos, setTotalInscritos] = useState(0)
    const [totalPagantes, setTotalPagantes] = useState(0)
    const [totalPago, setTotalPago] = useState(0)
    const [totalMeta, setTotalMeta] = useState(0)

    useEffect(() => {
        if (inscricoes) {
            setTotalInscritos(inscricoes.length)
            setTotalPagantes(inscricoes.filter(inscricao => inscricao.totalPago > 0).length)
            setTotalPago(inscricoes.reduce((acc, inscricao) => acc + inscricao.totalPago, 0))
        }

        if (congregacao == 'congregacao1') {
            setTotalMeta(50)
        } else if (congregacao == 'congregacao12') {
            setTotalMeta(25)
        } else if (congregacao == 'congregacao14') {
            setTotalMeta(50)
        } else if (congregacao == 'congregacao19') {
            setTotalMeta(30)
        } else if (congregacao == 'congregacao22') {
            setTotalMeta(15)
        } else if (congregacao == 'congregacaoLI') {
            setTotalMeta('0')
        }
    }, [inscricoes])

    const coluns = [
        {
            title: 'Jovem',
            dataIndex: 'nome',
            key: 'nome',
            render: (nome) => {
                return <span style={{ whiteSpace: 'nowrap' }}>
                    {nome}
                </span>
            }
        },
        {
            title: 'Whatsapp',
            dataIndex: 'whatsapp',
            key: 'whatsapp',
            render: (whatsapp) => {
                let whatsappFormatado = ''
                let link = '#'
                if (whatsapp) {
                    whatsappFormatado = whatsapp.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
                    link = 'https://wa.me/55' + whatsapp
                }
                return (
                    <a href={link} target="_blank">
                        <span style={{ whiteSpace: 'nowrap' }}>
                            {whatsappFormatado}
                        </span>
                    </a>
                )
            }
        },
        {
            title: 'Camisa',
            dataIndex: 'tamanhoCamisa',
            key: 'tamanhoCamisa',
            render: (tamanhoCamisa, record) => {
                return (
                    <>
                        <span style={{ whiteSpace: 'nowrap' }}>{tamanhoCamisa ?? '-'}</span>
                        { record.camisa && (<span style={{marginLeft: '5px'}}>✅</span>) }
                    </>
                )
            }
        },
        {
            title: '1ª Parcela',
            dataIndex: 'primeiraParcela',
            key: 'primeiraParcela',
            render: (primeiraParcela) => {
                return <Tag color={primeiraParcela ? 'green' : 'red'} style={{
                    whiteSpace: 'nowrap',
                    textAlign: 'right',
                    display: 'block',
                    fontSize: '12px'
                }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(primeiraParcela)}</Tag>
            }
        },
        {
            title: '2ª Parcela',
            dataIndex: 'segundaParcela',
            key: 'segundaParcela',
            render: (segundaParcela) => {
                return <Tag color={segundaParcela ? 'green' : 'red'} style={{
                    whiteSpace: 'nowrap',
                    textAlign: 'right',
                    display: 'block',
                    fontSize: '12px'
                }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(segundaParcela)}</Tag>
            }
        },
        {
            title: '3ª Parcela',
            dataIndex: 'terceiraParcela',
            key: 'terceiraParcela',
            render: (terceiraParcela) => {
                return <Tag color={terceiraParcela ? 'green' : 'red'} style={{
                    whiteSpace: 'nowrap',
                    textAlign: 'right',
                    display: 'block',
                    fontSize: '12px'
                }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(terceiraParcela)}</Tag>
            }
        },
        {
            title: '4ª Parcela',
            dataIndex: 'quartaParcela',
            key: 'quartaParcela',
            render: (quartaParcela) => {
                return <Tag color={quartaParcela ? 'green' : 'red'} style={{
                    whiteSpace: 'nowrap',
                    textAlign: 'right',
                    display: 'block',
                    fontSize: '12px'
                }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(quartaParcela)}</Tag>
            }
        },
        {
            title: 'Total Pago',
            dataIndex: 'totalPago',
            key: 'totalPago',
            render: (totalPago, record) => {
                let color = 'red'

                if (record.totalRestante == 0) {
                    color = 'green'
                } else {
                    if (totalPago > 0) {
                        color = 'orange'
                    } else {
                        color = 'red'
                    }
                }

                return <Tag color={color} style={{
                    whiteSpace: 'nowrap',
                    textAlign: 'right',
                    display: 'block',
                    fontSize: '12px'
                }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalPago)}</Tag>
            }
        },
        {
            title: 'Restante',
            dataIndex: 'totalRestante',
            key: 'totalRestante',
            render: (totalRestante, record) => {
                let color = 'red'

                if (totalRestante == 0) {
                    color = 'green'
                } else {
                    if (record.totalPago > 0) {
                        color = 'orange'
                    } else {
                        color = 'red'
                    }
                }

                return <Tag color={color} style={{
                    whiteSpace: 'nowrap',
                    textAlign: 'right',
                    display: 'block',
                    fontSize: '12px'
                }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalRestante)}</Tag>
            }
        },
    ]

    return (
        <div style={{ padding: '10px', fontSize: '12px' }}>
            <Row gutter={12}>
                <Col md={6} xs={24} style={{ marginBottom: '5px' }}>
                    <Card bordered={true} size='small'>
                        <Statistic
                            title="Inscritos"
                            value={totalInscritos}
                            valueStyle={{ color: '#3f8600' }}
                        />
                    </Card>
                </Col>
                <Col md={6} xs={24} style={{ marginBottom: '5px' }}>
                    <Card bordered={true} size='small'>
                        <Statistic
                            title="Pagantes"
                            value={totalPagantes}
                            valueStyle={{ color: '#3f8600' }}
                        />
                    </Card>
                </Col>
                <Col md={6} xs={24} style={{ marginBottom: '5px' }}>
                    <Card bordered={true} size='small'>
                        <Statistic
                            title="Meta"
                            value={totalMeta}
                            valueStyle={{ color: '#3f8600' }}
                        />
                    </Card>
                </Col>
                <Col md={6} xs={24} style={{ marginBottom: '5px' }}>
                    <Card bordered={true} size='small'>
                        <Statistic
                            title="Pago"
                            value={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalPago)}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                        />
                    </Card>
                </Col>
                <Col md={24} xs={24} style={{ marginTop: '30px' }}>
                    <Table
                        loading={loading}
                        columns={coluns}
                        rowKey={(record) => record.id}
                        dataSource={inscricoes}
                        size="small"
                    />
                </Col>
            </Row>
        </div>
    )
}

const TodasInscricoes = ({ somenteCongregacao = false }) => {
    const [inscricoes, setInscricoes] = useState([])
    const [loading, setLoading] = useState(false)
    const [congregacoes, setCongregacoes] = useState([])

    const carregaInscricoes = async () => {
        try {
            setLoading(true)
            const response = await api.get('/user/inscricoes-por-congregacao' + (somenteCongregacao ? '?somenteCongregacao=true' : ''))
            setInscricoes(response.data)
            var listaCongregacoes = []
            for (var congregacao in response.data) {
                if (response.data[congregacao].length > 0) {
                    listaCongregacoes.push(congregacao)
                }
            }
            setCongregacoes(listaCongregacoes)
        } catch (e) {
            console.log(e)
            message.error('Erro ao carregar inscrições')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        carregaInscricoes()
    }, [])

    return (
        <div style={{ backgroundColor: 'white', overflow: 'auto' }} >
            <Tabs
                defaultActiveKey={1}
                centered
                items={congregacoes.map((congregacao, index) => {
                    return {
                        label: `${congregacao.replace("congregacao", "").padStart(2, "0")}`,
                        key: index + 1,
                        children: <TabelaCongregacao congregacao={congregacao} inscricoes={inscricoes[congregacao]} loading={loading} />
                    }
                })}
            />
        </div>

    )
}

export default TodasInscricoes