import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const App = () => {
    const navigate = useNavigate()

    useEffect(() => {
        navigate('/extreme-acamp-2024')
    }, [])

    return (
        <></>
    )
}

export default App