import React from 'react'

import ieadam26 from '../../assets/images/ieadam-26.png'
import faixa1 from '../../assets/images/faixa-1.png'

const Rodape = () => {
    return (
        <>
            <div id="faixa5" className="faixa">
                <img src={faixa1} alt="Faixa 5" />
            </div>
            <div id="rodape">
                <p>© {new Date().getFullYear()} - Extreme Acamp <br />Todos os direitos reservados</p><br />
                <img src={ieadam26} alt="IEADAM Área 26" />
                <p>IEADAM Área 26 - Uma Igreja Apaixonante <span style={{ color: 'red' }}>♥</span></p>
            </div>
        </>
    )
}

export default Rodape