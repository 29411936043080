import { Card, Col, message, Row, Statistic, Table } from 'antd'
import React, { useEffect } from 'react'
import api from '../../services/api'
import { IconBounceLeft, IconBounceRight, IconBusinessplan } from '@tabler/icons-react'

const Saidas = () => {
    const [loading, setLoading] = React.useState(false)
    const [totalEntradas, setTotalEntradas] = React.useState(0)
    const [totalSaidas, setTotalSaidas] = React.useState(0)
    const coluns = [
        {
            title: 'Descrição',
            dataIndex: 'descricao',
            key: 'descricao',
            render: (text, record) => (
                <>
                    {record.descricao}
                </>
            )
        },
        {
            title: 'Data',
            dataIndex: 'dataPagamento',
            key: 'dataPagamento',
            render: (text, record) => (
                <>
                    {new Date(record.dataPagamento).toLocaleDateString()}
                </>
            )
        },
        {
            title: 'Forma de Pagamento',
            dataIndex: 'formaPagamento',
            key: 'formaPagamento',
        },
        {
            title: 'Valor',
            dataIndex: 'valor',
            key: 'valor',
            render: (text, record) => (
                <>
                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(record.valor)}
                </>
            )
        },
    ]
    const [registros, setRegistros] = React.useState([])

    useEffect(() => {
        loadRegistros()
    }, [])

    const loadRegistros = async () => {
        try {
            const response = await api.get('/saidas')
            setRegistros(response.data?.saidas ?? [])
            setTotalEntradas(response.data?.totalEntradas ?? 0)
            setTotalSaidas(response.data?.totalSaidas ?? 0)
        } catch (e) {
            message.error('Ocorreu um erro ao carregar os registros. Tente novamente mais tarde.')
        } finally {
            setLoading(false)
        }
    }

    return (
        <div style={{ padding: '10px', fontSize: '12px' }}>
            <Row gutter={12}>
                <Col md={8} xs={24} style={{ marginBottom: '5px' }}>
                    <Card bordered={true} size='small'>
                        <Statistic
                            prefix={<IconBounceRight />}
                            title="Total Saídas"
                            value={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalSaidas)}
                            valueStyle={{ color: 'red' }}
                        />
                    </Card>
                </Col>
                <Col md={8} xs={24} style={{ marginBottom: '5px' }}>
                    <Card bordered={true} size='small'>
                        <Statistic
                            prefix={<IconBounceLeft />}
                            title="Total Entradas"
                            value={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalEntradas)}
                            valueStyle={{ color: '#3f8600' }}
                        />
                    </Card>
                </Col>
                <Col md={8} xs={24} style={{ marginBottom: '5px' }}>
                    <Card bordered={true} size='small'>
                        <Statistic
                            prefix={<IconBusinessplan />}
                            title="Total Caixa"
                            value={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalEntradas - totalSaidas)}
                            valueStyle={{ color: 'blue' }}
                        />
                    </Card>
                </Col>
                <Col md={24} xs={24} style={{ marginTop: '30px' }}>
                    <div style={{ backgroundColor: 'white', overflow: 'auto' }} >
                        <Table
                            loading={loading}
                            columns={coluns}
                            rowKey={(record) => record.id}
                            dataSource={registros}
                            size="small"
                            pagination={{ showSizeChanger: false }}
                            locale={{ emptyText: 'Nenhum registro encontrado' }}
                        />
                    </div>

                </Col>
            </Row>
        </div>
    )
}

export default Saidas