import { Input } from "antd";
import { useEffect, useRef } from "react";
import { IMask } from "react-imask";

const MaskedInput = (props) => {
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            const maskOptions = {
                mask: props.mask || '000.000.000-00',
                definitions: {
                    '0': /[0-9]/
                }
            };

            const mask = IMask(inputRef.current.input, maskOptions);

            return () => mask.destroy();
        }
    }, []);

    return <Input {...props} ref={inputRef} />;
};

export default MaskedInput;