import React from 'react'

import faixa1 from '../../assets/images/faixa-1.png'
import faixa2 from '../../assets/images/faixa-2.png'
import extremeAcamp2024Logo from '../../assets/images/extreme-acamp-2024.png'
import backgroundExtreme from '../../assets/images/background.png'
import { useNavigate } from 'react-router-dom'


const Topo = ({ botoes, small }) => {
    const navigate = useNavigate()

    return (
        <>
            <div id="banner" style={{ backgroundImage: `url(${backgroundExtreme})` }} className={small ? 'small' : ''}>
                <img src={extremeAcamp2024Logo} alt="Extreme Acamp 2024" onClick={() => navigate('/extreme-acamp-2024')}/>

                {botoes ?? ''}
            </div>
            <div id="faixa1" className="faixa">
                <img src={faixa1} alt="Faixa 1" />
            </div>
            <div id="faixa2" className="faixa">
                <img src={faixa2} alt="Faixa 2" />
            </div>
        </>
    )
}

export default Topo